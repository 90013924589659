import React from 'react'
import styled from 'styled-components'
import { color, font, buttons, breakpoint } from '../styles'
import { index } from '../../data/index'

const ContactContainer = styled.div`
  width: 100%;
  margin: auto;

  .text {
    margin: 0 auto 4em;
    text-align: center;
  }

  .form {
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: relative;
    form {
      display: block;
      width: 100%;
      
      .form_group {
        display: inline-block;
        padding: 15px;
        min-width: 200px;
        &[data-size='100%'] {
          width: 100%;
        }
        &[data-size='50%'] {
          width: 50%;
        }
      }

      label {
        display: none;
      }
      input, textarea {
        font-family: ${font.family.default};
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        padding: 12px;
        font-size: 1em;
        transition: border .3s ease-in-out;
        
        &:focus:required:invalid {
          border-color: red;
          box-shadow: none;
          outline: none;
        }
        &:focus:required:valid {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
        &:invalid {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
      }
      .submit {
        text-align: center;
        margin: 30px auto 0;
      }
    }
    .response {
      color: ${color.text.white};
      text-align: center;
      .message {
        margin: 2em 0 0;
      }
      .status {
        margin: 0 auto;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        position: relative;
      }
      &.success {
        .status {
          animation: none;
          background: ${color.primary};
          &:before, &:after {
            content: '';
            position: absolute;
          }
          &:before {
            border-radius: 0;
            background: transparent;
            transform: rotate(45deg);
            top: 15%;
            left: 30%;
            height: 50%;
            width: 25%;
            border-bottom: 3px solid ${color.white};
            border-right: 3px solid ${color.white};
            z-index: 1;
          }
          &:after {
            background: ${color.primary};
          }
        }
      }
      &.error {
        .status {
          animation: none;
          background: red;
          &:before, &:after {
            position: absolute;
            content: '';
            height: 70%;
            width: 3px;
            background-color: ${color.white};
            border: 0;
            border-radius: 0;
            margin: 0;
            top: 15%;
            left: 44.5%;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &.index {
    margin-bottom: 0;
    padding-bottom: 3em;
    background-color: ${color.black};
    &:after {
      content: "";
      display: block;
      width: calc(100% - 80px);
      height: 1px;
      background-color: ${color.halfTransparent};
      margin: 3em auto 0;
      max-width: 1360px;
    }
    .text {
      h2 {
        color: ${color.text.white};
      }
      h4 {
        color: ${color.text.gray}
      }
    }
    .form {
      .form_group {
        input, textarea {
          background-color: transparent;
          color: ${color.text.white};
          border-color: ${color.white};
          &:focus:required:invalid {
            border-color: red;
            box-shadow: none;
            outline: none;
          }
          &:focus:required:valid {
            border-color: ${color.primary};
            box-shadow: none;
            outline: none;
          }
          &:invalid {
            box-shadow: none;
            outline: none;
          }
          &:focus {
            border-color: ${color.primary};
            box-shadow: none;
            outline: none;
          }
          &::placeholder {
            opacity: .8;
            color: ${color.white};
          }
        }
      }
      .submit {
        button {
          color: ${color.text.black};
          background-color: ${color.white};
          border-color: ${color.white};
          &:hover {
            color: ${color.text.white};
            background-color: ${color.primary};
            border-color: ${color.primary};
          }
        }

        &.loading {
          button {
            &:hover {
              color: ${color.text.black};
              background-color: ${color.white};
              border-color: ${color.white};
            }
          }
          .inner_button {
            margin: 0 auto;
            width: 18px;
            height: 18px;
            border-radius: 25px;
            background: #ffffff;
            background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            position: relative;
            animation: load 1.4s infinite linear;
            transform: translateZ(0);
            &:before {
              width: 50%;
              height: 50%;
              background: ${color.black};
              border-radius: 100% 0 0 0;
              position: absolute;
              top: 0;
              left: 0;
              content: '';
            }
            &:after {
              background: ${color.white};
              width: 75%;
              height: 75%;
              border-radius: 50%;
              content: '';
              margin: auto;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            }
            @-webkit-keyframes load {
              0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
            @keyframes load {
              0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoint.xsmall}){
    .form {
      form {
        .form_group {
          &[data-size='50%'] {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderResponse = this.renderResponse.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.encode = this.encode.bind(this);
    this.handleChange = this.handleChange.bind(this);
		this.render = this.render.bind(this);

		this.state = {
      formResponse: '',
      formStatus: null,
      loading: false,
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        botfield: ''
      }
    }
  }

  handleSubmit(e) {
    this.setState({
      formResponse: '',
      formStatus: null,
      loading: true
    })

    console.log(this.encode({ 'form-name': 'contact', ...this.state.form }) )

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'contact', ...this.state.form })
    }).then((res) => {
      this.setState({
        loading: false,
        formResponse: 'Message was sent successfully!',
        formStatus: 'success'
      })
    }).catch((error) => {
      this.setState({
        loading: false,
        formResponse: 'There was an error trying to send your message. Please try again later.',
        formStatus: 'error'
      })
    });

    e.preventDefault();
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleChange(e) {
    const { form } = {...this.state};
    const currentState = form;
    const { name, value } = e.target;
    currentState[name] = value;

    this.setState({ form: currentState });
  }

  renderResponse() {
    return (
      <div className={`response ${this.state.formStatus}`}>
        <div className='status'></div>
        <div className='message'>{this.state.formResponse}</div>
      </div>
    )
  }

  renderForm() {
    return (
      <form name='contact'
        data-netlify='true'
        data-netlify-honeypot='botfield'
        onSubmit={(e) => this.handleSubmit(e)}>
        <div hidden>
          <label htmlFor='botfield'>Don’t fill this out if you're human:</label>
          <input name='botfield' onChange={(e) => this.handleChange(e)} />
        </div>
        <div className='form_group' data-size='100%'>
          <label htmlFor='name'>Name:</label>
          <input 
            type='text'
            name='name'
            required={true}
            placeholder='Name'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='email'>Email:</label>
          <input 
            type='email'
            name='email'
            required={true}
            placeholder='Email'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='phone'>Phone:</label>
          <input 
            type='tel'
            name='phone'
            required={true}
            placeholder='Phone'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='100%'>
          <label htmlFor='message'>Message:</label>
          <textarea 
            type='text'
            name='message'
            placeholder='Message'
            onChange={(e) => this.handleChange(e)}
          ></textarea>
        </div>
        <div className={`submit ${this.state.loading ? 'loading' : ''}`}>
          <buttons.submit type='submit'>
            <div className='inner_button'>{!this.state.loading && 'Send'}</div>
          </buttons.submit>
        </div>
      </form>
    )
  }


  render() {
    const contact = index.contact;

    return (
      <ContactContainer id='Contact' className={`${this.props.class} ${this.state.loading ? 'loading' : ''}`}>
        <div className='text'>
          <h4>{contact.text.accent}</h4>
          <h2>{contact.text.title}</h2>
        </div>
        <div className='form'>
          { this.state.formStatus && this.renderResponse() }
          { !this.state.formStatus && this.renderForm() }
        </div>
      </ContactContainer>
    );
  }
}