import React from 'react'
import styled from 'styled-components'
import PlayIcon from '../../svgs/play.svg'

const BannerContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-self: center;
  position: relative;

  .inner_banner {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-self: center;
    margin: 0;
    overflow: hidden;
    width: 100%;
    video {
      width: 100%;
      background: #000;
    }
  }

  .play_button {
    z-index: 1;
    width: 20%;
    position: absolute;
    top: 35%;
    left: 40%;
    opacity: .75;
    cursor: pointer;
    .cls-1, .cls-2 {
      fill: #fff;
      stroke-width: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

export default class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    }

    this.toggleVideo = this.toggleVideo.bind(this);
		this.render = this.render.bind(this);
  }

  toggleVideo() {
    const video = document.getElementById('banner_video');

    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing) {
        video.play();
        video.setAttribute('controls', 'controls');
      } else {
        video.removeAttribute('controls');
      } 
    });
  }

  render() {
    const banner = this.props.banner;

    return (
      <BannerContainer id='Top'>
        <div className='inner_banner'>
          {/* { !this.state.playing &&
            <div id='play_button' role='button' className='play_button' onClick={() => this.toggleVideo()} onKeyDown={() => this.toggleVideo()} tabIndex={0}> 
              <PlayIcon/>
            </div>
          } */}
          <video id='banner_video' 
              playsInline={true}
              autoPlay={true}
              controls=''
              muted={true}
              loop={true}
              preload='true'
              // controls='true'
              // onPause={() => this.toggleVideo()} 
              // poster={banner.video.poster} 
            >
            <source src={banner.video.source} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
        </div>
      </BannerContainer>
    );
  }
}