import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { font, breakpoint } from '../components/styles'
import { index } from '../data/index'
import Banner from '../components/index/banner'
import About from '../components/index/about'
import Services from '../components/index/services'
import Clients from '../components/index/clients'
import Products from '../components/index/products'
import Process from '../components/index/process'
import Support from '../components/index/support'
import Team from '../components/index/team'
import Contact from '../components/index/contact'

const HomeContent = styled.div`
  width: 100%;

  h1 {
    font-size: 5em;
    margin: 0;
  }

  h2 {
    font-size: 5em;
    margin: 20px auto;
    &.thin {
      font-weight: 300;
    }
  }

  h3 {
    font-size: 1.25em;
    font-weight: 400;
  }

  h4 {
    font-size: 2em;
    font-weight: 300;
    margin: auto;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  p {
    color: #666;
    font-weight: 400;
    font-family: ${font.family.default};
    line-height: 1.5em;
  }

  > div {
    display: block;
    width: 100%;
    margin: 4em auto;
    padding: 6em 0;
    &:first-of-type {
      margin: 0 auto;
      padding: 0;
    }
  }

  @media screen and (max-width: ${breakpoint.large}){
    h2 {
      font-size: 4em;
    }

    h3 {
      font-size: 1.25em;
    }

    h4 {
      font-size: 1.5em;
    }

    > div {
      margin: 4em auto;
      padding: 4em 0;
      .text {
        padding: 0 20px;
      }
    }
  }
  @media screen and (max-width: ${breakpoint.medium}){
    h2 {
      font-size: 3em;
    }

    > div {
      margin: 3em auto;
      padding: 3em 0;
    }
  }
  @media screen and (max-width: ${breakpoint.small}){
    h2 {
      font-size: 2.5em;
    }

    > div {
      margin: 2em auto;
      padding: 2em 0; 
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeContent>
      <Banner banner={index.banner}/>
      <About about={index.about}/>
      <Clients clients={index.clients}/>
      <Services services={index.services}/>
      <Products products={index.products}/>
      <Process process={index.process}/>
      <Support support={index.support}/>
      <Team team={index.team}/>
      <Contact class='index'/>
    </HomeContent>
  </Layout>
)

export default IndexPage