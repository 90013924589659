import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../styles'

const ProcessContainer = styled.div`
  .text {
    margin: 0 auto 4em 4em;
    text-align: left;
    max-width: 1200px;
    h2, h3 {
      margin: 0;
    }
    &.secondary {
      max-width: 850px;
      margin: 0 auto;
    }
  }

  .image {
    width: 100%;
    margin: auto;
    position: relative;
    img {
      display: block;
      display: block;
      width: 100%;
      margin: auto;
    }
  }
  @media screen and (max-width: ${breakpoint.large}){
    .text {
      margin: 0 auto 4em 0;
    }
  }
`;

export default class Process extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const newProcess = this.props.process;

    return (
      <ProcessContainer id='Process'>
        <div className='text'>
          
          <h2 className='thin'>{newProcess.text.titleThin}</h2>
          <h2>{newProcess.text.title}</h2>
        </div>
        <div className='image'>
          <img src={newProcess.image.source} alt={newProcess.image.title}/>
        </div>
        <div className='text secondary'>
          <h3>{newProcess.text.secondary}</h3>
        </div>
      </ProcessContainer>
    );
  }
}