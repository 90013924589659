import React from 'react'
import styled from 'styled-components'
import { buttons, breakpoint } from '../styles'

const ProductsContainer = styled.div`
  .text {
    margin: 0 4em auto auto;
    text-align: right;
    max-width: 680px;
    h2, h3 {
      margin: 0;
    }
    h3 {
      max-width: 425px;
      margin-left: auto;
      margin-bottom: 50px;
    }
  }

  .image {
    width: 100%;
    max-width: 1000px;
    margin: auto 0;
    position: relative;


    img {
      display: block;
      display: block;
      width: 100%;
      margin: auto;
    }
  }

  @media screen and (max-width: ${breakpoint.large}){
    .text {
      margin: 0 0 auto auto;
    }
  }
`;

export default class Products extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const products = this.props.products;

    return (
      <ProductsContainer id='Products'>
        <div className='image'>
          <img src={products.image.source} alt={products.image.title}/>
        </div>
        <div className='text'>
          <h2>{products.text.title}</h2>
          <h2 className='thin'>{products.text.titleThin}</h2>
          <h3>{products.text.secondary}</h3>
          {/* <buttons.internal.primary to={products.button.products.link} title={products.button.products.text}>
            {products.button.products.text}
          </buttons.internal.primary> */}
        </div>
      </ProductsContainer>
    );
  }
}