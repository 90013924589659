import React from 'react'
import styled from 'styled-components'
import { buttons, breakpoint } from '../styles'

const ServicesContainer = styled.div`
  max-width: 1000px;

  .text {
    margin: 0;
    text-align: center;
    padding: 0 20px;
    h3 {
      margin: 0;
    }
  }

  .cards {
    display: flex;
    flex-flow: row wrap;
    margin: 5em auto 0;
    .card {
      flex: 1;
      width: 33.3%;
      flex-basis: 33.3%;
      min-width: 235px;
      margin: 0 auto;
      .card_inner {
        max-width: 245px;
        margin: 30px auto;
        padding: 0 15px;
        .text {
          text-align: center;
        }
        .icon {
          svg {
            margin: 0 auto 25px;
            max-width: 150px;
            max-height: 100px;
            display: block;
          }
        }
      }
    }
  }

  a {
    margin: 25px auto;
    display: block;
    max-width: 310px;
  }

  @media screen and (max-width: ${breakpoint.small}){
    .cards {
      .card {
        margin: 0 auto 3em;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default class Services extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const services = this.props.services;

    return (
      <ServicesContainer id='Services'>
        <div className='text'>
          <h4>{services.text.accent}</h4>
          <h2>{services.text.title}</h2>
          <p>{services.text.secondary}</p>
        </div>
        <div className='cards'>
          {
            Object.keys(services.list).map((key) => {
              let Icon = services.list[key].icon;
              return (
                <div className='card' key={key}>
                  <div className='card_inner'>
                    <div className='icon'>
                      <Icon />
                    </div>
                    <div className='text'>
                      <h3>{services.list[key].name}</h3>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <buttons.internal.primary to={services.button.contact.link} title={services.button.contact.text}>
            {services.button.contact.text}
          </buttons.internal.primary>
      </ServicesContainer>
    );
  }
}