import React from 'react'
import styled from 'styled-components'

const TeamContainer = styled.div`
  .text {
    margin: 0 auto 4em;
    text-align: center;
    &.secondary {
      max-width: 850px;
      margin: 0 auto;
    }
  }

  .image {
    width: 100%;
    margin: auto;
    position: relative;
    img {
      display: block;
      display: block;
      width: 100%;
      margin: auto;
    }
  }
`;

export default class Team extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const team = this.props.team;

    return (
      <TeamContainer id='Team'>
        <div className='text'>
          <h4>{team.text.accent}</h4>
          <h2>{team.text.title}</h2>
        </div>
        <div className='image'>
          <img src={team.image.source} alt={team.image.title}/>
          <div className='background'></div>
        </div>
        <div className='text secondary'>
          <h3>{team.text.secondary}</h3>
        </div>
      </TeamContainer>
    );
  }
}