import React from 'react'
import styled from 'styled-components'
import { color, buttons, breakpoint } from '../styles'

const AboutContainer = styled.div`
  max-width: 1000px;

  .row {
    display: flex;
    flex-flow: row wrap;
    margin: 2em auto;
    &.bottom {
      justify-content: space-between;
      margin-top: 6em;
      > div {
        max-width: 450px;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    
    > div {
      flex: 1;
      min-width: 300px;
      display: flex;
      flex-flow: column;
      justify-content: start;
    }
  }

  .text {
    padding: 0 20px;
    h2 {
      text-align: center;
      margin-bottom: 2em;
    }
    h3 {
      border-bottom: 2px solid ${color.black};
      font-size: 1.5em;
      padding-bottom: 1em;
      margin: 0;
      font-weight: 500;
    }
    p {
      font-size: 1.25em;
      font-weight: 400;
    }
    a {
      margin: 30px auto 0 0;
      display: inline-block;
    }
  }

  .graph {
    padding: 0 60px;
    h3 {
      font-weight: 500;
      margin: .5em 0
    }
    .bar {
      display: flex;
      align-items: center;
    }
    .line {
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      background: ${color.black};
      margin-right: 15px;
    }
    .us .line {
      width: 20%;
    }
    .others .line {
      width: 80%;
    }
  }

  a {
    display: block;
    max-width: 190px;
    margin: 50px auto 0;
  }

  @media screen and (max-width: ${breakpoint.small}){
    &#About {
      margin-bottom: 2em;
    }
    padding-top: 2em;
    .row {
      position: relative;
    }
  }
`;

export default class About extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const about = this.props.about;

    return (
      <AboutContainer id='About'>
        <div className='text'>
          <h2>{about.text.title}</h2>
        </div>
        <div className='row'>
          <div className='text'>
            <h3>{about.content.builtForSpeed.text.title}</h3>
            <p>{about.content.builtForSpeed.text.description}</p>
          </div>
        </div>
        <div className='row'>
          <div className='graph'>
            <div className='us'>
              <h3>{about.content.builtForSpeed.graph.us.title}</h3>
              <div className='bar'>
                <div className='line'></div>
                <h3>{about.content.builtForSpeed.graph.us.time}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='graph'>
            <div className='others'>
              <h3>{about.content.builtForSpeed.graph.others.title}</h3>
              <div className='bar'>
                <div className='line'></div>
                <h3>{about.content.builtForSpeed.graph.others.time}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='row bottom'>
          <div className='column'>
            <div className='text'>
              <h3>{about.content.privateBrandedPowerhouse.text.title}</h3>
              <p>{about.content.privateBrandedPowerhouse.text.description}</p>
            </div>
          </div>
          <div className='column'>
            <div className='text'>
              <h3>{about.content.fullyAutomated.text.title}</h3>
              <p>{about.content.fullyAutomated.text.description}</p>
            </div>
          </div>
        </div>
        <buttons.internal.primary to={about.button.contact.link} title={about.button.contact.text}>
          {about.button.contact.text}
        </buttons.internal.primary>
      </AboutContainer>
    );
  }
}