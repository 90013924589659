import React from 'react'
import styled from 'styled-components'
import { buttons, breakpoint } from '../styles'

const SupportContainer = styled.div`
  .text {
    margin: 0 4em auto auto;
    text-align: right;
    max-width: 1200px;
    h3 {
      max-width: 350px;
      margin-left: auto;
      margin-bottom: 50px;
    }
  }

  .image {
    width: 100%;
    margin: auto;
    position: relative;
    img {
      display: block;
      display: block;
      width: 100%;
      margin: auto;
    }
  }

  @media screen and (max-width: ${breakpoint.large}){
    .text {
      margin: 0 0 2em auto;
    }
  }
`;

export default class Support extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const support = this.props.support;

    return (
      <SupportContainer id='Support'>
        <div className='text'>
          <h2>{support.text.title}</h2>
          <h3>{support.text.secondary}</h3>
          {/* <buttons.internal.primary to={support.button.caseStudies.link} title={support.button.caseStudies.text}>
            {support.button.caseStudies.text}
          </buttons.internal.primary> */}
        </div>
        <div className='image'>
          <img src={support.image.source} alt={support.image.title}/>
        </div>
      </SupportContainer>
    );
  }
}