import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../styles'

const ClientsContainer = styled.div`
  background: #333;
  color: #fff;
  padding: 40px 0;

  .text {
    text-align: center;
    margin: 0 auto 4em;
    h4 {
      color: #ccc;
    }
  }

  .client_list {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .inner_list {
      display: block;
      text-align: center;
      margin: 0;
      padding: 0;
      background: #333;
    }
    .client{
      display: inline-block;
      vertical-align: middle;
      max-width: 150px;
      margin: auto 15px;
      opacity: .75;
      &:hover {
        opacity: 1;
      }
      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: ${breakpoint.small}){
    .client_list {
      .client {
        max-width: 24%;
        min-width: 80px;
      }
    }
  }
  
`;

export default class Clients extends React.Component {
  constructor(props) {
    super(props);
		this.render = this.render.bind(this);
  }

  render() {
    const clients = this.props.clients;

    return (
      <ClientsContainer id='Clients'>
        <div className='text'>
          <h4>{clients.text.accent}</h4>
          <h2>{clients.text.title}</h2>
        </div>
        <div className='client_list'>
          <ul className='inner_list'>
            {
              Object.keys(clients.list).map((key) => {
                return (
                  <li className='client' key={key}>
                    <img src={clients.list[key].logo} alt={clients.list[key].name}/>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </ClientsContainer>
    );
  }
}